.table-body-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .table-body-topic {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
  }

  .table-body-topic-text {
    color: var(--gray_03);
  }

  .task-board-header-item {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    color: var(--gray_01);
    padding: 4px;
    width: 100px;
  }

  .board-table-filter-pos {
    position: absolute;
    top: -15px;
    left: -160px;
  }

  .date-table-filter-pos {
    position: absolute;
    right: -9px;
    top: -3px;
  }

  .status-table-filter-pos {
    position: absolute;
    right: -10px;
    top: -3px;
  }

  .tags-table-filter-pos {
    position: absolute;
    right: -10px;
    top: -3px;
  }

  .members-table-filter-pos {
    position: absolute;
    right: -10px;
    top: -3px;
  }
}

.task-page-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 26px 14px;

  .task-page-title-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 17px;
  }

  .task-page-name-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
  }

  .task-page-title-feature {
    color: var(--gray_03);
  }
}

.all-task-container {
  border-spacing: 0 !important;
  border-collapse: collapse;

  .task-filter-setting-icon {
    width: 12px;
    height: 12px;
    stroke: var(--gray_0) !important;
  }

  .task-filter-setting-name {
    color: var(--gray_0);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 7px;
    justify-content: center;
  }

  .board-table-filter-pro-pos {
    position: absolute;
    top: -3px;
    right: -7px;
  }

  .add-task-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    align-items: center;
    width: 100%;
    padding: 2px 0 2px 10px;
  }

  .add-task-name-focus {
    transition: all 0.3s;
  }

  .add-task-name {
    width: 408px;
    background-color: var(--white);
  }

  .add-task-name::-webkit-input-placeholder {
    color: var(--blue_strong);
  }

  .add-task-name:focus::-webkit-input-placeholder {
    color: var(--gray_01);
  }

  .add-task-arrow {
    fill: var(--blue_strong) !important;
  }

  .add-task-plus-icon {
    stroke: var(--white) !important;
  }

  .add-task-supercheck-icon {
    stroke: var(--gray_04) !important;
    background-color: var(--white) !important;
  }

  .add-task-done-supercheck-icon {
    stroke: var(--white) !important;
    background-color: var(--green) !important;
  }

  .add-task-check:hover .add-task-supercheck-icon {
    stroke: var(--green) !important;
    background-color: var(--lime_light) !important;
  }

  .add-task-plus {
    background-color: var(--blue_strong);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-task-check {
    background-color: var(--white);
    border-radius: 50%;
    border: 2px solid var(--gray_03);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1ms;
  }

  .add-task-done-check {
    background-color: var(--green);
    border-radius: 50%;
    border: 2px solid var(--green);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1ms;
  }

  .add-task-check:hover {
    background-color: var(--lime_light);
    border: 2px solid var(--green);
  }

  .task-table-td,
  .task-table-th {
    border: 1px solid var(--gray_0);
    height: 23px;
    max-height: 23px;
    text-align: center;
    padding: 4px 10px;
  }

  .task-table-tr .task-table-td:first-child,
  .task-table-tr .task-table-th:first-child {
    border-left: none;
  }

  .task-table-tr .task-table-td:last-child,
  .task-table-tr .task-table-th:last-child {
    border-right: none;
  }

  .task-table-tr:last-child .task-table-td,
  .task-table-tr:last-child .task-table-th {
    border-bottom: none;
  }

  .task-filter-name-color {
    color: var(--gray_0);
  }

  .task-table-pro-card-pos {
    position: absolute;
    right: -11px;
  }
}

.task-table-card {
  .task-table-card-name-container {
    display: flex;
    align-items: center;
    gap: 9px;
    flex-flow: row nowrap;
  }

  .task-table-card-status-icon-container {
    border-radius: 50%;
    border: 1px solid var(--gray_03);
    padding: 1px 3px;
  }

  .task-table-card-status-icon-container:hover {
    background-color: var(--green_light);
    border: 1px solid var(--green);
    transition: all 0.3s;
  }

  .task-table-card-status-icon-container:hover .task-table-card-status-icon {
    stroke: var(--green);
  }

  .task-table-card-status-icon-container-done {
    border: 1px solid var(--green);
    background-color: var(--green);
    transition: all 0.3s;
  }

  .task-table-card-status-icon {
    stroke: var(--gray_03);
  }

  .task-table-card-status-done {
    transition: all 0.3s;
    stroke: var(--white);
  }

  .task-table-card-name {
    width: 380px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: start;
  }

  .task-table-card-number {
    color: var(--gray_0);
  }

  .task-table-card-board-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    border: 1px solid var(--gray_0);
    padding: 1px 2px;
    border-radius: 2px;
  }

  .task-table-card-board-container:hover {
    border: 1px solid var(--dark_05);
    transition: all 0.3s;
  }

  .task-table-card-board-container:hover .task-table-card-board-name {
    transition: all 0.3s;
    color: var(--dark_05);
  }

  .task-table-card-exist-date {
    min-width: 124px;
  }

  .task-table-card-board-name {
    color: var(--gray_0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
  }

  .table-card-board-pos {
    position: absolute;
    top: 11px;
    right: 269px;
  }

  .task-table-card-parent-container {
    display: flex;
    justify-content: center;
    border-radius: 2px;
    padding: 1px 2px;
  }

  .task-table-card-parent-container:hover .task-table-card-parent {
    color: var(--dark_05);
    transition: all 0.3s;
  }

  .task-table-card-parent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--gray_01);
    width: 85%;
    padding: 0 5px;
    border-radius: 3px;
  }

  .task-board-card-status-container {
    width: 7%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
  }

  .task-table-card-status-container {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
  }

  .task-table-status-menu {
    position: absolute;
    top: 11px;
    right: 173px;
  }

  .task-table-card-status {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .status-icon {
    width: 21px;
    height: 21px;
  }

  .card-status-tool {
    top: 188% !important;
    left: -18% !important;
    margin-left: 0 !important;
  }

  .task-board-new-tag {
    color: var(--gray_01);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 86px;
  }

  .task-board-tag-container:hover .task-board-new-tag {
    color: var(--black);
  }

  .task-board-tag {
    border-radius: 4px;
    color: var(--gray_01);
  }

  .task-board-tag-container {
    border-radius: 4px;
    // max-width: 86px;
    display: flex;
    justify-content: center;
  }

  .task-board-tag-container:hover .task-board-tag {
    color: var(--black);
    transition: all 0.3s;
  }

  .task-table-tag-menu {
    position: absolute;
    right: -17px;
  }

  .task-table-card-members-container {
    display: flex;
    gap: 5px;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .task-table-card-more-members {
    background-color: var(--gray_05);
    color: var(--gray_0);
    border-radius: 4px;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 2px;
    min-width: 22px;
  }

  .task-table-card-more-members:hover {
    color: var(--black);
    transition: all 0.3s;
  }

  .task-table-card-new-date {
    color: var(--gray_0);
  }

  .task-table-card-new-date:hover {
    color: var(--black);
    transition: all 0.3s;
  }

  .task-table-select-member-menu-pos {
    position: absolute;
    right: -7px;
  }
  .task-table-more-member-pos {
    position: absolute;
    right: -7px;
  }

  .tasks-table-actions-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: center;
  }

  .delete-task-icon {
    stroke: var(--gray_01);
  }

  .duplicate-task-icon {
    stroke: var(--gray_01);
  }

  .duplicate-task-icon:hover {
    stroke: var(--black);
    transition: all 0.3s;
  }

  .delete-task-icon:hover {
    stroke: var(--black);
    transition: all 0.3s;
  }
}

.my-focus-container {
  margin-top: -14px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.by-boards-container {
  margin-top: -14px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.all-tasks-container {
  margin-top: -14px;
}

.task-board-card-member-container {
  display: flex;
  width: 281px;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 4px;
  min-height: 194px;
  max-height: 196px;
  overflow-y: auto;
}

.task-board-comment-container:hover .task-board-comment-icon {
  color: var(--black) !important;
}

.task-board-card-member-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 6px 8px;
  border-bottom: 1px solid var(--gray_06);
}

.task-board-card-member-clear {
  color: var(--gray_01);
}

.task-board-card-member-search {
  background-color: var(--white) !important;
  display: flex;
  align-items: center;
  border: 1px solid var(--gray_04);
  padding: 0 10px;
  height: 36px;
  width: 261px;
  border-radius: 4px;
  margin: 14px 10px 8px 10px;
}

.task-board-members-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 4px !important;
}

.invite-member-task-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
  margin: 0 8px;
}

.invite-member-task {
  color: var(--blue_strong);
}

.invite-member-task-icon {
  stroke: var(--blue_strong) !important;
}

.menu-member-list-container {
  display: flex;
  flex-direction: column;
  width: 281px;
  border-radius: 4px;
  background-color: var(--white);
  height: 160px;
  overflow-y: auto;
}

.menu-member-list-header {
  display: flex;
  flex-flow: row nowrap;
  padding: 6px 8px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray_06);
}

.menu-member-header-add {
  display: flex;
  align-items: center;
  gap: 6px;
}

.menu-member-header-add-icon {
  stroke: var(--gray_01) !important;
}

.menu-member-header-delete {
  color: var(--gray_01);
}
