.popup-menu-columns {
  background-color: var(--white);
  width: 197px;
  height: 324px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow:
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  .status-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px;
    cursor: url('../assets/svg/cursor-pointer.svg'), auto;
  }

  .float-menu-openMenu-status {
    width: 34px;
    height: 34px;
    border-radius: 3px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .float-menu-status-check {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding-right: 4px;
  }

  .popup-menu-status-name {
    padding: 6px 8px;
    text-align: left;
  }
}
