.avatar-pic {
  color: var(--white) !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 12px;
  margin: 4px 0 0 0;
  height: 44px;
  border: var(--border-gray);
  border-radius: 6px;
}
.text-input:focus-within {
  border: var(--border-blue);
  border-width: 2px;
}
.text-input input {
  height: 100%;
  width: 100%;
  margin: 0px;
  background-color: var(--white);
}

.text-input-title {
  opacity: 0.5;
  margin-left: 2px;
  width: 63px;
}
.text-error {
  opacity: 1;
  color: var(--red);
}

.text-input-message {
  margin-top: 2px;
  margin-left: 2px;
  color: var(--red);
}
