.task-tabs-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;

  .task-tabs-item {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    transition: all 0.1s;
    padding: 5px;
    opacity: 0.6;
    height: 40px;
  }

  .task-tabs-item-selected {
    border-bottom: 2px solid var(--black);
    transition: all 0.1s;
    opacity: 1;
  }

  .task-tabs-item-text {
    transition: all 0.2s;
  }

  .task-tabs-item-text-selected {
    transition: all 0.2s;
  }

}
