.task-board-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .task-board-header-container {
    display: flex;
    flex-flow: row nowrap;
    background-color: var(--gray_06);
    height: 32px;
    width: 100%;
    padding: 0 24px;
  }

  .task-board-header-item {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    color: var(--gray_01);
    padding: 4px;
    width: 100px;
  }

  .task-board-header-item-icon {
    stroke: var(--gray_01) !important;
  }

  .task-board-header-item:hover {
    color: var(--black);
  }

  .task-board-header-item:hover .task-board-header-item-icon {
    stroke: var(--black) !important;
  }

  .task-board-header-tooltip {
    width: 84px !important;
    padding: 3px 6px !important;
    left: -2% !important;
    margin-left: 0 !important;
  }

  .task-board-header-status {
    width: 86px !important;
    padding: 3px 6px !important;
    margin-left: 0 !important;
    left: -9% !important;
  }

  .task-board-header-tags {
    width: 66px !important;
    padding: 3px 6px !important;
    margin-left: -0 !important;
    left: -4% !important;
  }

  .task-board-header-member {
    width: 90px !important;
    padding: 3px 6px !important;
    margin-left: 0 !important;
    left: -4% !important;
  }

  .task-board-body-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    background-color: var(--white);
  }

  .task-card-container {
    display: flex;
    flex-direction: column;
  }

  .add-task-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid var(--gray_05);
    width: 100%;
    padding: 12px 24px;
  }

  .add-task-name-focus {
    border-bottom: 1px solid var(--blue_strong);
    transition: all 0.3s;
  }

  .add-task-name {
    width: 408px;
    background-color: var(--white);
  }

  .add-task-name::-webkit-input-placeholder {
    color: var(--blue_strong);
  }

  .add-task-name:focus::-webkit-input-placeholder {
    color: var(--gray_01);
  }

  .add-task-arrow {
    fill: var(--blue_strong) !important;
  }

  .add-task-plus-icon {
    stroke: var(--white) !important;
  }

  .add-task-supercheck-icon {
    stroke: var(--gray_04) !important;
    background-color: var(--white) !important;
  }

  .add-task-done-supercheck-icon {
    stroke: var(--white) !important;
    background-color: var(--green) !important;
  }

  .add-task-check:hover .add-task-supercheck-icon {
    stroke: var(--green) !important;
    background-color: var(--lime_light) !important;
  }

  .add-task-plus {
    background-color: var(--blue_strong);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-task-check {
    background-color: var(--white);
    border-radius: 50%;
    border: 2px solid var(--gray_03);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1ms;
  }

  .add-task-done-check {
    background-color: var(--green);
    border-radius: 50%;
    border: 2px solid var(--green);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1ms;
  }

  .add-task-check:hover {
    background-color: var(--lime_light);
    border: 2px solid var(--green);
  }

  .task-board-card-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    background-color: var(--white);
    padding: 12px 24px;
    align-items: center;
    border-bottom: 1px solid var(--gray_05);
  }

  .task-board-card-container:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 3;
  }

  .task-board-card-name-container {
    display: flex;
    flex-flow: row nowrap;
    width: 35%;
    gap: 8px;
    align-items: center;
  }

  .task-board-card-name-container-fixed-width {
    overflow-y: hidden;
    overflow-x: auto;
    width: 35%;
  }
  .outline-view-node-dot {
    top: 3px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--gray_09);
  }

  .task-board-card-title-member-more {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray_05);
    padding: 6px 4px;
    border-radius: 6px;
    width: 35px;
    height: 24px;
  }

  .task-board-arrow-icon {
    fill: var(--gray_03) !important;
    transform: all 0.3s;
  }

  .task-board-card-plus-icon {
    stroke: var(--gray_0) !important;
  }

  .task-board-card-more-icon-wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    align-items: center;
    visibility: hidden;
  }

  .task-board-comment-icon {
    color: var(--gray_01);
    width: 16px;
    height: 20px;
  }

  .task-board-pencil-container:hover .task-board-pencil-icon {
    stroke: var(--black) !important;
  }

  .task-board-pencil-icon {
    stroke: var(--gray_01) !important;
  }

  .task-view-buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 35px;
    width: 17%;
  }

  .task-board-card-container:hover .task-board-card-more-icon-wrapper {
    visibility: visible;
  }

  .task-board-card-plus-wrapper {
    width: 10px;
  }

  .card-sub-tool {
    margin-left: 0 !important;
    top: 220% !important;
    left: -275% !important;
    width: 74px;
  }

  .task-board-card-plus-wrapper:hover .task-board-card-plus-icon {
    stroke: var(--black) !important;
  }

  .task-board-more-icon {
    fill: var(--gray_0) !important;
  }

  .task-board-more-wrapper {
    width: 20px;
    display: flex;
    justify-content: center;
  }

  .task-board-more-wrapper:hover .task-board-more-icon {
    fill: var(--black) !important;
  }

  .task-board-arrow-open-child {
    fill: var(--black) !important;
    transform: rotate(90deg);
    transition: all 0.3s;
  }

  .task-board-card-title {
    color: var(--gray_0);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 6px;
  }

  .card-exist-board-icon {
    visibility: hidden;
    stroke: var(--gray_0) !important;
  }

  .task-board-card-title:hover .card-exist-board-icon {
    visibility: visible;
  }

  .task-board-title-container:hover .card-exist-board-icon {
    stroke: var(--black) !important;
  }

  .cehvron-right-icon {
    transform: rotate(180deg);
    fill: var(--gray_0) !important;
  }

  .task-board-title-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    width: 15%;
  }

  .task-board-title-container:hover .task-board-card-title {
    color: var(--black) !important;
  }

  .task-board-title-container:hover .cehvron-right-icon {
    fill: var(--black) !important;
  }

  .task-board-project-tool {
    left: -26% !important;
    top: 187% !important;
    margin-left: 0 !important;
    width: 75px;
    padding: 3px 6px !important;
  }

  .task-board-new-project-tool {
    left: -11% !important;
  }

  .task-board-card-name {
    width: 100%;
    background-color: var(--white);
  }

  .task-board-card-title-input {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .task-board-card-blur-title {
    margin: 0 10px;
    height: 26px;
    width: 100%;
  }

  .task-board-card-empty-name {
    color: var(--gray_01) !important;
    display: flex;
    align-items: center;
    cursor: text;
  }

  .task-board-card-date {
    width: 12%;
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
    align-items: center;
  }

  .task-board-card-date:hover .card-date-new-icon {
    visibility: visible;
    stroke: var(--black) !important;
  }

  .task-board-card-date:hover .task-board-card-exist-date {
    color: var(--black) !important;
  }

  .task-board-card-new-date {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--gray_0);
    gap: 6px;
  }

  .task-board-card-exist-date {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--red);
    gap: 6px;
  }

  .card-date-exist-icon {
    visibility: hidden;
  }

  .task-board-card-exist-date:hover {
    color: var(--black) !important;
  }

  .task-board-card-exist-date:hover .card-date-exist-icon {
    stroke: var(--black) !important;
    visibility: visible;
  }

  .task-board-pro-card-pos {
    position: absolute;
  }

  .card-date-new-icon {
    stroke: var(--gray_0) !important;
    visibility: hidden;
  }

  .task-board-card-date:hover .task-board-card-new-date {
    color: var(--black);
  }

  .task-board-card-new-date:hover .card-date-new-icon {
    visibility: visible;
  }

  .task-board-card-date:hover .card-date-icon {
    visibility: visible;
  }

  .task-board-card-date-tool {
    top: 189% !important;
    left: -1% !important;
    margin-left: 0 !important;
  }

  .card-date-icon {
    stroke: var(--red) !important;
    visibility: hidden;
  }

  .task-board-card-status-container {
    width: 7%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
  }

  .card-status-icon {
    visibility: hidden;
  }

  .card-status-tool {
    top: 188% !important;
    left: -18% !important;
    margin-left: 0 !important;
  }

  .task-board-status-menu {
    position: absolute;
    top: 18px;
    right: 217px;
  }

  .task-board-tag-menu {
    position: absolute;
    top: 25px;
    right: 10px;
  }

  .task-board-more-status-menu {
    position: absolute;
    bottom: 200px;
    right: 215px;
  }

  .task-board-card-status-container:hover .card-status-icon {
    visibility: visible;
  }

  .task-card-status-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: var(--red_light);
    border-radius: 4px;
  }

  .task-card-status-new-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .status-icon {
    width: 20px;
    height: 20px;
  }

  .status-new-icon {
    stroke: var(--gray_01) !important;
  }

  .card-status-new-icon {
    stroke: var(--gray_01) !important;
  }

  .task-board-tag-container {
    width: 14%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
  }

  .card-tag-icon {
    visibility: hidden;
    stroke: var(--gray_0);
  }

  .task-board-tag-container:hover .card-tag-icon {
    visibility: visible;
    stroke: var(--black) !important ;
  }

  .task-board-tag-container:hover .task-board-new-tag {
    color: var(--black);
  }

  .task-board-tag-container:hover .task-board-tag {
    color: var(--black);
  }

  .task-board-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1px;
    border-radius: 4px;
    color: var(--gray_01);
    width: 34px;
  }

  .task-board-new-tag {
    color: var(--gray_01);
  }

  .card-tag-tool {
    margin-left: 0 !important;
    top: 188% !important;
    left: -3% !important;
  }

  .card-pen-tool {
    margin-left: 0 !important;
    top: 188% !important;
    left: -76% !important;
  }

  .card-comment-tool {
    margin-left: 0 !important;
    top: 188% !important;
    left: -129% !important;
  }

  .task-board-members-container {
    display: flex;
    width: 13%;
    gap: 6px;
  }

  .task-board-new-members {
    color: var(--gray_01);
    display: flex;
    align-items: center;
  }

  .task-board-member-menu-pos {
    position: absolute;
    right: 156px;
    top: -8px;
  }

  .task-more-menu-pos {
    position: absolute;
    top: 16px;
    right: -21px;
  }

  .task-more-menu-pos-new {
    position: absolute;
    top: 16px;
    right: -25px;
  }

  .task-board-select-member-menu-pos {
    position: absolute;
    top: 19px;
    right: -8px;
  }

  .task-board-assign-member {
    position: absolute;
    top: 21px;
    right: 536px;
  }

  .task-board-exist-members {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
  }

  .task-board-members-container:hover .card-member-icon {
    visibility: visible;
    stroke: var(--black) !important;
  }

  .task-board-members-container:hover .task-board-new-members {
    color: var(--black) !important;
  }

  .card-member-tool {
    margin-left: 0 !important;
    top: 187% !important;
    left: 0 !important;
  }

  .card-member-old-tool {
    margin-left: 0 !important;
    top: 187% !important;
    left: -23px !important;
  }

  .card-member-icon {
    visibility: hidden;
    stroke: var(--gray_01) !important;
  }

  .task-board-title-space {
    padding: 0 24px;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
  }

  .task-board-title-filter {
    display: flex;
    flex-flow: row wrap;
    gap: 6px;
  }

  .task-board-title-number {
    min-width: 80px;
  }

  .task-board-sub-tool {
    top: 44px !important;
  }

  .task-board-more-status-position {
    position: absolute;
    right: 0;
    bottom: -28px;
  }

  .task-board-status-position-menu {
    position: absolute;
    right: 190px;
  }

  .task-board-card-member-container {
    display: flex;
    width: 281px;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 4px;
    min-height: 194px;
    max-height: 196px;
    overflow-y: auto;
  }

  .task-board-comment-container:hover .task-board-comment-icon {
    color: var(--black) !important;
  }

  .task-board-card-member-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 6px 8px;
    border-bottom: 1px solid var(--gray_06);
  }

  .task-board-card-member-clear {
    color: var(--gray_01);
  }

  .task-board-card-member-search {
    background-color: var(--white) !important;
    display: flex;
    align-items: center;
    border: 1px solid var(--gray_04);
    padding: 0 10px;
    height: 36px;
    width: 261px;
    border-radius: 4px;
    margin: 14px 10px 8px 10px;
  }

  .task-board-members-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 4px !important;
  }

  .invite-member-task-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    margin: 0 8px;
  }

  .invite-member-task {
    color: var(--blue_strong);
  }

  .invite-member-task-icon {
    stroke: var(--blue_strong) !important;
  }

  .menu-member-list-container {
    display: flex;
    flex-direction: column;
    width: 281px;
    border-radius: 4px;
    background-color: var(--white);
    height: 160px;
    overflow-y: auto;
  }

  .menu-member-list-header {
    display: flex;
    flex-flow: row nowrap;
    padding: 6px 8px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray_06);
  }

  .menu-member-header-add {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .menu-member-header-add-icon {
    stroke: var(--gray_01) !important;
  }

  .menu-member-header-delete {
    color: var(--gray_01);
  }

  .task-board-more-deadline {
    position: absolute;
    bottom: -242px;
    right: 0;
  }

  .task-board-more-member-pos {
    position: absolute;
    right: 10px;
    top: 19px;
  }

  .task-board-more-member-more-pos {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  .deadLine-pro-pos {
    position: absolute;
    bottom: -28px;
    right: -1px;
  }

  .task-board-card-member-container-pos {
    position: absolute;
    right: 288px;
  }

  .menu-member-select-pos {
    position: absolute;
    bottom: -87px;
    right: 279px;
  }

  .select-board-filter-pos {
    position: absolute;
    right: 77px;
    top: 0px;
  }

  .select-board-filter-pos-pro {
    position: absolute;
    right: -145px;
    top: 12px;
  }

  .select-status-filter-pos {
    position: absolute;
    left: -75px;
    top: 11px;
  }

  .select-members-filter-pos {
    position: absolute;
    top: 11px;
    right: -12px;
  }

  .select-date-filter-pos {
    position: absolute;
    left: -84px;
    top: 11px;
    z-index: 1000;
  }

  .select-tags-filter-pos {
    position: absolute;
    left: -60px;
    top: 11px;
  }

  .tasks-selected-filter {
    color: var(--black);
  }

  .tasks-selected-filter-icon {
    stroke: var(--black) !important;
  }

  .task-section-container {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
  }

  .task-section-title {
    margin-left: 35.5%;
    margin-top: 12px;
  }
}
